<template>
  <PokerGame/>
</template>

<script>
import PokerGame from './components/PokerGame.vue'

export default {
  name: 'App',
  components: {
    PokerGame
  }
}
</script>
